import { get } from "src/common/apiClient";
import { getConfig } from "src/apiConfig";
import {
  handleErrors,
  mapTimeFrames,
  toSpinalCase,
  ITINERARYDETAILS,
  shouldMockData,
} from "src/common";
import moment from "moment";
import { WidgetI } from "src/types/Widget";

const currentTime = moment();

interface TTWidgetI {
  isVisible?: boolean;
  [key: string]: unknown;
  colspan?: 1 | 2 | 3 | 4;
  timeFrames?: {
    previous: number;
    upcoming: number;
  };
  id?: string;
  displayRiskMessages?: boolean;
  defaultCountry?: string;
}

export function getTravelerTrackerProperties(widgets: WidgetI[], id) {
  let TTWidget = widgets.find((item) => item.id === id);
  if (!TTWidget)
    TTWidget = widgets.find(
      (item) => item.identifier === "Elysium_Traveler_Tracker_Widget"
    );
  if (!TTWidget) return {};
  let properties = {} as TTWidgetI;
  if (TTWidget.properties) properties = TTWidget.properties;
  if (!properties.timeFrames)
    properties.timeFrames = { previous: 1, upcoming: 3 };
  properties.id = TTWidget.id;
  return properties;
}

export function filterStatus(status, displayRiskMessages) {
  if (
    status.text === "Car" ||
    status.text === "Hotel" ||
    status.text === "Rail"
  )
    return false;
  if (status.code === "at-risk") return displayRiskMessages;
  else return true;
}

async function getMockItineraries(properties) {
  const { mockItineraries } = await import("src/common/mocks/data/flights.js");
  return mockItineraries(properties);
}

export const fetchData = (properties) => {
  const { timeFrames, id } = properties;
  const url = getConfig(ITINERARYDETAILS).url;
  if (url) {
    const { startDate, endDate } = mapTimeFrames(timeFrames);
    const startDateStr = startDate.toISOString();
    const endDateStr = endDate.toISOString();
    return get({
      url,
      params: {
        startDate: startDateStr,
        endDate: endDateStr,
        CompanyFeatureID: id,
        getActiveFlights: false,
      },
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((res) => {
        if (shouldMockData())
          return getMockItineraries(properties).then((mockResponse) => [
            ...res,
            ...mockResponse,
          ]);
        return res;
      })
      .catch(() => {
        if (shouldMockData()) {
          return getMockItineraries(properties);
        }
      });
  } else {
    return Promise.reject(new Error(`Missing ${ITINERARYDETAILS} url`));
  }
};

export const fetchDataWithCustomDates = ({ startDate, endDate, id }) => {
  const url = getConfig(ITINERARYDETAILS).url;
  if (url) {
    const startDateStr = moment(startDate).toISOString();
    const endDateStr = moment(endDate).toISOString();
    return get({
      url,
      params: {
        startDate: startDateStr,
        endDate: endDateStr,
        CompanyFeatureID: id,
        getActiveFlights: false,
      },
    })
      .then(handleErrors)
      .then((res) => res.json());
  } else {
    return Promise.reject(new Error(`Missing ${ITINERARYDETAILS} url`));
  }
};

/**
 * Compute statuses from flight data to display in Traveler Tracker widget and full page
 */
export const computeStatuses = (itineraries, flightStatusEnum) => {
  if (!itineraries || !Array.isArray(itineraries)) return [];
  // Define our statuses and their rendering order
  const statusLabels = [
    "Not Departed",
    "En-Route",
    "Landed",
    "On Time",
    "Delayed",
    "At Risk",
    "All Travel",
    "Hotel",
    "Car",
    "Rail",
  ];

  // Factory function for flight status buckets
  function statusGenerator(text: string, statusCode?: string, code?: string) {
    const result = {
      text,
      code: code || toSpinalCase(text),
      isSelected: false,
      segments: [],
      count: 0,
      id: null as string,
      isCalculated: null as boolean,
    };
    if (statusCode) result.id = statusCode;
    else result.isCalculated = true;
    return result;
  }

  // Loop over our statuses to define our object to collect status data
  const statusObject = {};
  Object.keys(flightStatusEnum).forEach((statusCode) => {
    const status = flightStatusEnum[statusCode];
    if (status.isVisible) {
      statusObject[status.label] = statusGenerator(status.label, statusCode);
    }
  });

  // Add Risk and All Travel to the Status Object
  statusObject["Car"] = statusGenerator("Car", "Car", "Car");
  statusObject["Hotel"] = statusGenerator("Hotel", "Hotel", "Hotel");
  statusObject["Rail"] = statusGenerator("Rail", "Rail", "Rail");
  statusObject["At Risk"] = statusGenerator("At Risk");
  statusObject["All Travel"] = statusGenerator("All Travel", null, "all");

  function flightSegmentGenerator(itin, flight) {
    // Make sure a status and label exists for the flight's Status before proceeding
    const status = flightStatusEnum[flight.flightStatus];
    // If there is no status filter the flight out
    if (!status) return;
    const label = flightStatusEnum[flight.flightStatus].label;
    // If there is no associated label, filter the flight out
    if (!label) return;
    // If we have not determined this status is part of our status object, filter the flight out
    if (!statusObject[label]) return;

    const flightData = {
      ...flight,
      id: itin.id,
      passengers: itin.passengers,
      type: "flight",
      dateStart: flight.locations.origin.utcTime,
      coords: flight.locations.origin,
    };

    // Add the flightData to the status category's flights and increment the count
    if (flight.isDelayed) {
      // Check if delayed
      statusObject["Delayed"].segments.push(flightData);
      statusObject["Delayed"].count += itin.passengers.length;
    } else if (label === "En-Route" || label === "Not Departed") {
      // Calculated on-time: Not Departed + En-Route - Delayed = On Time
      statusObject["On Time"].segments.push(flightData);
      statusObject["On Time"].count += itin.passengers.length;
    }
    statusObject[label].segments.push(flightData);
    statusObject[label].count += itin.passengers.length;

    // If the flighthas riskData, add the flight to risk flights and increment the count
    const { destination, origin } = flight.locations;
    const destinationData = Array.isArray(destination.riskData)
      ? destination.riskData
      : [];
    const originData = Array.isArray(origin.riskData) ? origin.riskData : [];
    const riskData = [...originData, ...destinationData];
    if (riskData.length) {
      let maxRiskLevel = 0;
      const riskLevels = {};
      riskData.forEach((rd) => {
        const ratingID = rd.riskRatingID;
        if (ratingID && ratingID > maxRiskLevel) maxRiskLevel = ratingID;
        if (!riskLevels[ratingID]) riskLevels[ratingID] = true;
      });
      flightData.riskLevel = maxRiskLevel;
      flightData.riskLevels = riskLevels;
      statusObject["At Risk"].segments.push(flightData);
      statusObject["At Risk"].count += itin.passengers.length;
    }

    // Add the flight to the All bucket as well
    statusObject["All Travel"].segments.push(flightData);
    statusObject["All Travel"].count += itin.passengers.length;
  }

  function carSegmentGenerator(itin, carRental) {
    let location, pickUpLocation, dropOffLocation;
    if (carRental.location.pickUp && carRental.location.pickUp.location) {
      pickUpLocation = carRental.location.pickUp.location;
    } else if (
      carRental.location.dropOff &&
      carRental.location.dropOff.location
    ) {
      dropOffLocation = carRental.location.dropOff.location;
    } else {
      return;
    }
    if (!dropOffLocation) location = pickUpLocation;
    else if (!pickUpLocation) location = dropOffLocation;
    else if (currentTime.isBefore(moment(carRental.dateStart))) {
      location = pickUpLocation;
    } else {
      location = dropOffLocation;
    }

    const carData = {
      ...carRental,
      pnr: itin.pnrLocator,
      id: itin.id,
      passengers: itin.passengers,
      type: "car",
      coords: location,
    };
    statusObject["Car"].segments.push(carData);
    statusObject["All Travel"].segments.push(carData);
    statusObject["All Travel"].count += itin.passengers.length;
  }

  function hotelSegmentGenerator(itin, hotel) {
    const hotelData = {
      ...hotel,
      pnr: itin.pnrLocator,
      id: itin.id,
      passengers: itin.passengers,
      type: "hotel",
      dateStart: hotel.statuses.stay.dateStart,
      coords: hotel.company.location,
    };
    statusObject["Hotel"].segments.push(hotelData);
    statusObject["All Travel"].segments.push(hotelData);
    statusObject["All Travel"].count += itin.passengers.length;
  }

  function railSegmentGenerator(itin, rb) {
    const dateStart = moment(rb.start.date);
    const railData = {
      ...rb,
      pnr: itin.pnrLocator,
      id: itin.id,
      passengers: itin.passengers,
      type: "rail",
      dateStart,
      coords: currentTime.isBefore(dateStart)
        ? rb.start.station.coordinates
        : rb.end.station.coordinates,
    };
    statusObject["Rail"].segments.push(railData);
    statusObject["All Travel"].segments.push(railData);
    statusObject["All Travel"].count += itin.passengers.length;
  }
  // Loop over itineraries and fill our status buckets
  itineraries.forEach((itin) => {
    // Make sure we have flights on the itinerary before doing trying to map them
    if (itin.flights.outbound && itin.flights.outbound.length) {
      itin.flights.outbound.forEach((flight) => {
        flightSegmentGenerator(itin, flight);
      });
    } else if (itin.carRentals && itin.carRentals.length) {
      itin.carRentals.forEach((carRental) => {
        carSegmentGenerator(itin, carRental);
      });
    } else if (itin.hotels && itin.hotels.length) {
      itin.hotels.forEach((hotel) => {
        hotelSegmentGenerator(itin, hotel);
      });
    } else if (itin.rail?.length) {
      itin.rail.forEach((rb) => {
        railSegmentGenerator(itin, rb);
      });
    }
  });
  // Return our presorted status array mapped to our new data
  return statusLabels.map((label) => statusObject[label]);
};
